import { createContext, useContext } from 'react';

import { QueryLoading } from '@frx/frx-components';
import { node } from 'prop-types';

import { useLoginTest, useLoginUrl, useTokenRefresh, useUser } from '../api';

const defaultContext = {
  data: {
    authenticated: false,
    loggedIn: false,
    token: undefined,
    user: undefined,
  },
};

export const AuthContext = createContext(defaultContext);

export const AuthProvider = ({ children }) => {
  const loginUrl = useLoginUrl();
  const user = useUser();
  const loginTest = useLoginTest();
  useTokenRefresh();

  if (loginUrl.isLoading || loginTest.isLoading)
    return <QueryLoading label="Loading..." />;

  const context = {
    data: {
      authenticated: !!loginUrl.data?.token,
      loggedIn: !!loginTest.data?.cons_id,
      token: loginUrl.data?.token,
      user: user.data,
    },
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: node,
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context)
    throw new Error('Must be a children to AuthProvider to use context');
  return context;
};
