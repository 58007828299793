import { node } from 'prop-types';

import { Footer } from './Footer';
import { Header } from './Header';
import styles from './Layout.module.scss';

export const Layout = ({ children }) => (
  <div className={styles.container}>
    <Header />
    <section className="section">
      <div className="container">
        <main>{children}</main>
      </div>
    </section>
    <Footer />
  </div>
);

Layout.propTypes = {
  children: node,
};
