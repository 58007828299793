export const withHttp = (url) =>
  !/^https?:\/\//i.test(url) ? `https://${url}` : url;

// builds url with additional pre if not localhost
export const routeResolver = (url) => {
  if (!window.location.hostname.includes('localhost')) {
    return `${'/site/SPageServer/'}${url}`;
  }
  return url;
};
