import { useState } from 'react';

import { RichText } from '@frx/frx-components';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { stjudelogo, sjoLogo } from '../assets';
import styles from './Header.module.scss';

export const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  const location = useLocation();
  const isGeneral =
    location?.pathname?.includes('not-found') ||
    location?.pathname?.includes('login') ||
    location?.pathname?.includes('UserLogin') ||
    location?.pathname?.includes('create-account');

  const handleMobileMenu = () =>
    setIsMobileMenuVisible((prevState) => !prevState);

  const mobileMenuClass = isMobileMenuVisible && 'is-active';

  const dropDownClass = isMobileMenuVisible ? 'is-active' : '';

  return (
    <header data-testid="header" className={styles.container}>
      <nav
        className="navbar is-spaced has-shadow"
        role="navigation"
        aria-label="main navigation"
      >
        <div className={cn('navbar-brand', styles.navbarBrand)}>
          <div className={cn('navbar-wrap', styles.navbarWrap)}>
            <a
              data-testid="logo"
              href="https://www.stjude.org/"
              className={cn('navbar-item', styles.navbarItem)}
            >
              {isGeneral ? (
                <div className={styles.sjoLogo}>
                  <img
                    src={sjoLogo}
                    alt={`St. Jude Children's Research Hospital`}
                  />
                </div>
              ) : (
                <>
                  <div className={styles.sjLogo}>
                    <img
                      src={stjudelogo}
                      alt="St Jude Logo"
                      style={{ display: 'none' }}
                    />
                  </div>
                  <h1 className={styles.programName}>
                    <RichText
                      content={`<strong>St. Jude</strong> Children's<br>Research Hospital`}
                    />
                  </h1>
                </>
              )}
            </a>
          </div>
          <div
            className={cn('navbar-wrap', 'is-hidden-tablet', styles.navbarWrap)}
          >
            <button
              type="button"
              id="navbarBurger"
              onClick={handleMobileMenu}
              className={cn(
                'navbar-button',
                styles.navbarButton,
                mobileMenuClass
              )}
            >
              <div className={styles.navbarMenuText}>
                {isMobileMenuVisible ? 'Close' : 'Menu'}
              </div>
              <div
                className={cn(
                  'navbar-burger',
                  styles.navbarBurger,
                  mobileMenuClass
                )}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </div>
            </button>
          </div>
          <div
            id="navMenu"
            data-menu="main"
            className={cn('navbar-menu', styles.navbarMenu, dropDownClass)}
          >
            <div className={cn('navbar-end', styles.navbarEnd)}>
              <a
                className={cn('navbar-item', styles.navbarItem)}
                href="https://www.stjude.org/contact-us.html"
              >
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
