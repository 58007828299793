import { Redirect, Route, Switch } from 'react-router';
import { routeResolver } from './utils';
import { Layout } from './layout';
import { CreateAccount, Login, NotFound, Survey } from './screens';

const App = () => (
  <Layout>
    <Switch>
      <Route path={routeResolver('create-account')} component={CreateAccount} />
      <Route path={routeResolver('login')} component={Login} />
      <Route path="/survey" component={Survey} />
      <Route path="/not-found" component={NotFound} />
      <Route path="*">
        <Redirect to="/not-found" />
      </Route>
    </Switch>
  </Layout>
);
export default App;
