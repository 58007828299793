import { surveyApi } from '@frx/frx-services';
import { useQuery } from 'react-query';

import { useAuth } from '../../providers';
import { useQueryString } from '../../use-query-string';

export const useSurvey = () => {
  const {
    data: { token },
  } = useAuth();
  const {
    queryStrings: { survey_id },
  } = useQueryString();
  const { getSurvey } = surveyApi;
  const { data, error, isLoading, isSuccess } = useQuery(['survey'], () =>
    getSurvey({
      survey_id,
      auth: token,
    })
  );

  return {
    error: data?.code ? { ...data } : error,
    isLoading,
    isSuccess,
    survey: data?.survey,
    token,
  };
};
