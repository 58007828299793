import './index.scss';
import React from 'react';

import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { DefaultProvider } from '@frx/frx-react-context';
import { QueryLoading } from '@frx/frx-components';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';

import App from './App';
import { AuthProvider } from './hooks/providers';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <DefaultProvider useDevTools loader={<QueryLoading />}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ErrorBoundary
            FallbackComponent={({ error }) => (
              <div role="alert">
                <p>Something went wrong:</p>
                <pre style={{ color: 'red' }}>{error.message}</pre>
              </div>
            )}
          >
            <AuthProvider>
              <App />
            </AuthProvider>
          </ErrorBoundary>
        </BrowserRouter>
      </QueryClientProvider>
    </DefaultProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
