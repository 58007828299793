import { useState } from 'react';

import {
  Icon,
  colors,
  LoginForm,
  Button,
  NotificationType,
} from '@frx/frx-components';

import {
  useForgotPassword,
  useLogin,
  useQueryString,
  useAppContext,
  useLogout,
} from '@frx/frx-react-context';
import { useHistory } from 'react-router-dom';

import { withHttp } from '../../utils';

import styles from './Login.module.scss';

export const defaultNotificationState = {
  type: NotificationType.success,
  message: '',
};

export const Login = () => {
  const [notification, setNotification] = useState(defaultNotificationState);

  const { mutate: login, isLoading } = useLogin();
  const { mutate: forgotUserName } = useForgotPassword();
  const { push } = useHistory();
  const { queryStrings } = useQueryString();
  const { mutate: logout } = useLogout();

  const {
    data: {
      authData: { loggedIn, user },
    },
  } = useAppContext();

  const handleLogin = (values) => {
    setNotification(defaultNotificationState);
    login(
      { userName: values?.userName, password: values?.password },
      {
        onSuccess: () => {
          // redirect if query param exists
          if (queryStrings?.redirectUrl) {
            window.location.href = withHttp(queryStrings?.redirectUrl);
            return false;
          }
          // query redirect not present
          push('/not-found');
        },
        onError: (error) =>
          setNotification({
            type: NotificationType.error,
            message: error?.message,
          }),
      }
    );
  };

  const handleForgotUserName = (values) => {
    setNotification(defaultNotificationState);
    forgotUserName(
      { email: values.email, send_user_name: true },
      {
        onSuccess: (res) =>
          setNotification({
            type: NotificationType.success,
            message: res?.message,
          }),
      }
    );
  };

  return (
    <>
      <div className="has-text-centered mb-6">
        <Icon
          name="stjudeLogo"
          color={colors.primary.swatch1}
          width="100"
          viewBox="0 0 174 45"
          height="100"
        />

        <h1 className="is-size-3 has-text-weight-semibold">Log in</h1>
        <h3 className="is-size-4 has-text-weight-semibold">
          with your St. Jude account
        </h3>
      </div>

      <div className={styles.loginForm}>
        {loggedIn ? (
          <p className="has-text-centered">
            You are logged in as {user?.name?.first} {user?.name?.last}
            <div>
              <Button variant="text" onClick={() => logout()}>
                Log Out
              </Button>
            </div>
          </p>
        ) : (
          <>
            <LoginForm
              forgotUserNameProps={{
                onSubmitClick: handleForgotUserName,
              }}
              forgotPasswordProps={{
                onSubmitClick: handleForgotUserName,
              }}
              loading={isLoading}
              messageFromResponse={notification}
              onLoginPress={handleLogin}
            />
            <Button
              className={styles.createButton}
              variant="secondary"
              buttonColor="blue"
              isfullWidth
              onClick={() => push('/site/SPageServer/create-account')}
            >
              Create An Account
            </Button>
          </>
        )}
      </div>
    </>
  );
};
