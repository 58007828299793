import { constituentApi } from '@frx/frx-services';
import { useMutation, useQuery, useQueryClient } from 'react-query';

export const useToken = () => {
  const queryClient = useQueryClient();
  const authData = queryClient.getQueryData(['auth', 'loginUrl']);
  return authData?.token;
};

export const useConsId = () => {
  const queryClient = useQueryClient();
  const sessionData = queryClient.getQueryData(['auth', 'loginTest']);
  return sessionData?.cons_id;
};

export const useLogin = () => {
  const { login } = constituentApi;
  const queryClient = useQueryClient();

  return useMutation(
    ['login'],
    ({ username, password }) => login({ userName: username, password }),
    {
      onSuccess: async (res) => {
        if (res.code) {
          throw new Error(res.message);
        }
        await queryClient.invalidateQueries(['auth', 'loginTest']);
      },
    }
  );
};

export const useLogout = () => {
  const { logout } = constituentApi;
  const queryClient = useQueryClient();

  return useMutation(['auth', 'logout'], () => logout(), {
    onSuccess: async () => {
      await queryClient.invalidateQueries(['auth', 'loginTest']);
    },
  });
};

export const useForgotPassword = () => {
  const { login } = constituentApi;

  return useMutation(['auth', 'forgotPassword'], ({ email }) =>
    login({ email, send_user_name: true })
  );
};

export const useLoginUrl = () => {
  const { getLoginUrl } = constituentApi;

  return useQuery(['auth', 'loginUrl'], () => getLoginUrl());
};

export const useTokenRefresh = () => {
  const { getLoginUrl } = constituentApi;

  return useQuery(['tokenRefresh'], () => getLoginUrl(), {
    refetchInterval: 5 * 60 * 1000,
  });
};

export const useLoginTest = () => {
  const { loginTest } = constituentApi;
  const queryClient = useQueryClient();

  return useQuery(['auth', 'loginTest'], () => loginTest(), {
    onSettled: async () => {
      await queryClient.invalidateQueries(['auth', 'loginUrl']);
    },
    retry: false,
  });
};

export const useUser = () => {
  const { getUser } = constituentApi;
  const token = useToken();

  return useQuery(
    ['auth', 'getUser', { token }],
    () => getUser({ auth: token }),
    {
      enabled: !!token,
      keepPreviousData: false,
      staleTime: Infinity,
    }
  );
};
