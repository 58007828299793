import cn from 'classnames';
import dayjs from 'dayjs';

import styles from './Footer.module.scss';

export const Footer = () => (
  <footer
    data-testid="footer"
    className={cn('footer', 'has-text-centered', styles.container)}
  >
    <div className="container">
      <div className="copyright content">
        <p>
          &copy; Copyright {dayjs().format('YYYY')}. St. Children's Research
          Hospital, a not-for-profit, section 501(c)(3).
        </p>
      </div>
      <nav id="footerNav" className={styles.footerNav} role="navigation">
        <a
          href="https://www.stjude.org/legal/u-s-privacy-policy-statement.html"
          target="_blank"
          rel="noreferrer"
        >
          U.S. Privacy Notice
        </a>
      </nav>
    </div>
  </footer>
);
