import { useState } from 'react';

import { colors, CreateAccountForm, Icon } from '@frx/frx-components';
import {
  useCaslLanguage,
  useCaslLanguageUpdate,
  useCreateUserFlow,
  useQueryString,
} from '@frx/frx-react-context';
import { useHistory } from 'react-router-dom';

import { withHttp } from '../../utils';

import styles from './CreateAccount.module.scss';

export const CreateAccount = () => {
  const { queryStrings } = useQueryString();
  const { push } = useHistory();

  const { data: caslContent } = useCaslLanguage();
  const [message, setMessage] = useState('');
  const { mustate: changeCountry } = useCaslLanguageUpdate();
  const { mutate: createUser, isLoading, isError, reset } = useCreateUserFlow();

  const handleCountryChange = (country) => {
    changeCountry({ language: 'en', country });
  };

  const handleCreate = (data) => {
    setMessage('');
    createUser(data, {
      onSuccess: () => {
        reset();
        // redirect if query param exists
        if (queryStrings?.redirectUrl) {
          window.location.href = withHttp(queryStrings?.redirectUrl);
          return false;
        }
        push('/not-found');
      },
      onError: (err) => setMessage(err?.message),
    });
  };

  return (
    <div className={styles.container}>
      <div className="has-text-centered mb-2">
        <Icon
          name="stjudeLogo"
          color={colors.primary.swatch1}
          width="100"
          viewBox="0 0 174 45"
          height="100"
        />

        <h1 className="is-size-3 has-text-weight-semibold">
          Create your account
        </h1>
      </div>
      <CreateAccountForm
        className={styles.formContainer}
        caslText={caslContent?.consent_language || ''}
        onCreatePress={handleCreate}
        onCountryChange={handleCountryChange}
        isLoading={isLoading}
        isError={isError}
        message={message}
      />
    </div>
  );
};

export default CreateAccount;
