import { useState } from 'react';

import { Button, Figure, QueryLoading, TextInput } from '@frx/frx-components';
import cn from 'classnames';
import { Controller, useForm } from 'react-hook-form';

import { errorImage } from '../../assets';
import styles from './NotFound.module.scss';

export const NotFound = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit } = useForm({ mode: 'onSubmit' });

  const handleSearch = ({ search }) => {
    setLoading(true);
    window.location.href = `https://www.stjude.org/search-results.html?q=${encodeURI(
      search
    )}`;
  };

  return (
    <div className={cn('columns', styles.columns)}>
      <div className="column">
        <Figure
          src={errorImage}
          className={styles.patientPhoto}
          alt="St. Jude patient Deuce"
        >
          <p className={styles.caption}>St. Jude patient Deuce</p>
        </Figure>
      </div>

      <div className="column">
        <h1 className="title is-size-1">Uh-oh!</h1>
        <p className="is-size-4 mb-4">
          We&apos;re sorry, but we couldn&apos;t find the page you requested.
        </p>
        {loading && <QueryLoading />}
        {!loading && (
          <form onSubmit={handleSubmit(handleSearch)}>
            <Controller
              control={control}
              defaultValue=""
              name="search"
              render={({ field: { name, onChange, value } }) => (
                <TextInput
                  label="Search our site"
                  name={name}
                  onChange={onChange}
                  rightIcon={
                    <span
                      key="icon"
                      className={cn('icon is-right', styles.icon)}
                    >
                      <Button
                        type="submit"
                        iconProps={{
                          name: 'search',
                          viewBox: '0 0 48 48',
                          size: 28,
                        }}
                        className={cn(styles.search)}
                      />
                    </span>
                  }
                  value={value}
                />
              )}
            />
          </form>
        )}

        <p className="has-text-weight-bold has-text-centered mt-5 mb-5">
          - or -
        </p>

        <div className={cn(styles.btn)}>
          <a href="https://www.stjude.org/">
            <Button isfullWidth variant="primary" buttonColor="blue">
              Return to Home Page
            </Button>
          </a>
        </div>

        <div className={cn(styles.btn)}>
          <a href="https://www.stjude.org/donate/donate-to-st-jude.html">
            <Button isfullWidth variant="primary" buttonColor="red">
              Donate to St. Jude
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
