import { useState } from 'react';

import { QueryLoading, SurveyForm } from '@frx/frx-components';
import { surveyApi } from '@frx/frx-services';
import find from 'lodash/find';
import get from 'lodash/get';

import Login from '../../components/Login';
import Logout from '../../components/Logout';
import { useSurvey } from '../../hooks/api';
import { useAuth } from '../../hooks/providers';

export const Survey = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formError, setFormError] = useState(null);
  const [success, setSuccess] = useState(null);

  const { data } = useAuth();
  const { loggedIn, token, user } = data;
  const { survey, isSuccess } = useSurvey();

  const surveyQuestions = get(survey, 'surveyQuestions');
  const consInfoQuestion = find(
    surveyQuestions,
    (q) => q.questionType === 'ConsQuestion'
  );
  const loginPrompt = get(
    consInfoQuestion,
    'questionTypeData.consRegInfoData.loginPrompt',
    false
  );

  const { submitSurvey } = surveyApi;

  const handleSubmit = async (fields) => {
    setLoading(true);
    setErrors(null);
    setFormError(null);

    try {
      const response = await submitSurvey({
        survey_id: survey.surveyId,
        auth: token,
        ...fields,
      });
      if (!response.success || response.success === 'false') {
        throw response;
      }

      setSuccess(response);
      return response;
    } catch (error) {
      const errorsArray = !Array.isArray(error.errors)
        ? [error.errors]
        : error.errors;
      setFormError(
        !error.message
          ? 'An unexpected error occurred, please review your answers'
          : error.message
      );
      setErrors(errorsArray);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <QueryLoading label="Loading" />;

  return (
    <div className="columns is-centered">
      <div className="column is-two-thirds">
        {loginPrompt && !loggedIn && (
          <div className="mb-5">
            <p className="has-text-centered is-size-4 mb-5">
              If you already have an account, please login before filling out
              the survey
            </p>
            <Login />
          </div>
        )}
        {loggedIn && (
          <div className="mb-5">
            <Logout />
          </div>
        )}
        {isSuccess && survey && (
          <SurveyForm
            auth={data}
            formError={formError}
            errors={errors}
            success={success}
            survey={survey}
            onSubmit={handleSubmit}
            user={user}
          />
        )}
      </div>
    </div>
  );
};

export default Survey;
