import { useState } from 'react';

import { LoginForm } from '@frx/frx-components';

import { useForgotPassword, useLogin } from '../../hooks/api';

const Login = () => {
  const [notification, setNotification] = useState({
    type: '',
    message: '',
  });
  const { mutateAsync: login, isLoading } = useLogin();
  const { mutateAsync: forgotUserName } = useForgotPassword();

  const handleLogin = (values) => {
    console.log(values);
    login({ username: values?.userName, password: values?.password }).then(
      () => {
        setNotification({ type: 'success', message: 'You are logged in' });
      },
      (err) => setNotification({ type: 'error', message: err.message })
    );
  };

  const handleForgotUserName = (values) =>
    forgotUserName({ email: values.email, send_user_name: true }).then((res) =>
      setNotification({ type: 'success', message: res?.message })
    );

  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-half has-background-light">
          <LoginForm
            forgotUserNameProps={{
              onSubmitClick: handleForgotUserName,
            }}
            forgotPasswordProps={{
              onSubmitClick: handleForgotUserName,
            }}
            messageFromResponse={notification}
            onLoginPress={handleLogin}
            loading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
