import { Button } from '@frx/frx-components';

import { useLogout } from '../../hooks/api';
import { useAuth } from '../../hooks/providers';

const Logout = () => {
  const {
    data: { user },
  } = useAuth();
  const { mutateAsync: logout } = useLogout();
  return (
    <section className="is-fullheight">
      <div className="container">
        <div className="columns is-centered">
          <div className="column has-background-light">
            <h2 className="title has-text-primary is-4">
              You are logged in as {user?.name?.first} {user?.name?.last}.
            </h2>
            <Button
              buttonColor="red"
              size="small"
              variant="primary"
              onClick={() => logout().then(() => window.location.reload)}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Logout;
